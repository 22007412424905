import React, { useState } from 'react';
import { Layout, Panel, PanelContent, PanelHeader, Title } from './styled';
const numberArray = [2024, 2023, 2022, 2021, 2020];
const historyData = {
    2024: {
        '12월': '산업통산자원부 사업재편계획 승인(신사업 진출 유형)',
        '11월': '한국일보 2024 디지털 이노베이션 대상 선정(정보기술분야)',
        '6월': 'TIPS-해외마케팅 선정',
        '5월': '창업도약패키지 선정',
        '1월': 'Pre-A 투자 유치(교보증권, 20억원)',
    },
    2023: {
        '3월': 'Pre-A 투자 완료 20억',
    },
    2022: {
        '12월': 'IBK I-ONE inAPP 제휴',
        '10월': ['한국 핀테크 지원센터 금융 테스트베드 "위탁테스트(교보생명)" 선정', '교보생명 이노 스테이지 선정'],
        '9월': 'DGB 대구은행 청구서비스 제휴',
        '7월': 'DB 손해보험 청구서비스 제휴',
        '4월': '한국 핀테크 지원센터 핀테크 큐브 4기 선정',
        '3월': 'IBK I-ONE 뱅크 인앱 제휴',
    },
    2021: {
        '11월': '라이프캐치 APP 정식 오픈 (Android, IOS, Web)',
        '10월': 'KM-eng 협약 체결 (판례 검색엔진 공급)',
        '7월': 'IBK 창공 7기 선정',
        '5월': 'HY 창업경진대회 입상',
        '3월': '청년창업사관학교 선정',
    },
    2020: {
        '12월': '벤처기업 인증',
        '8월': '기업부설연구소 설립',
        '5월': '기술융합 소셜벤처 선정',
        '3월': '(주)그린리본 법인 설립',
    },
};
export function CompanyHistory() {
    const [PanelTab, setPanelTab] = useState(numberArray[0]);
    return (React.createElement("div", { id: "3" },
        React.createElement(Layout, null,
            React.createElement(Title, null,
                "\uADF8\uB9B0\uB9AC\uBCF8\uC740",
                React.createElement("br", null),
                React.createElement("span", null, "\uB9E4\uB144 \uC0C8\uB85C\uC6B4 \uBAA8\uC2B5"),
                "\uC744 \uADF8\uB824\uB098\uAC11\uB2C8\uB2E4.")),
        React.createElement(Panel, null,
            React.createElement(PanelHeader, null, numberArray.map((value) => {
                return (React.createElement("div", { key: value, onClick: () => setPanelTab(value), style: {
                        color: PanelTab === value ? '#000000' : '#B5B5B5',
                        cursor: 'pointer',
                        fontSize: '40px',
                        fontWeight: '700',
                        lineHeight: '125%',
                        letterSpacing: '-0.035em',
                        borderBottom: PanelTab === value ? '4px solid black' : '',
                    } }, value));
            })),
            React.createElement(PanelContent, null, Object.entries(historyData[PanelTab]).map(([key], index) => {
                return (React.createElement("div", { key: 'history-' + PanelTab + '-' + index, style: {
                        display: 'flex',
                        gap: '24px',
                    } },
                    React.createElement("div", { style: {
                            width: '38px',
                            whiteSpace: 'nowrap',
                        } }, key),
                    React.createElement("div", { style: {
                            whiteSpace: 'pre-line',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '24px',
                        } }, typeof historyData[PanelTab][key] === 'string'
                        ? historyData[PanelTab][key]
                        : historyData[PanelTab][key].map((value, id) => {
                            return React.createElement("div", { key: 'historyData' + id }, value);
                        }))));
            })))));
}
